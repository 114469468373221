<template lang="pug">
div(v-if='Clientes')
    .d-none.d-md-block
        v-container(fluid v-scrollanimation).pa-0
            .bloque-ubicacion.azul
            .fila-texto
                h3( style='line-height : 50px' v-html='Clientes.titulo').white--text
                img(:src='Inicio.cuadro_recortado')
        
        v-row.my-12(align='center' justify='center')
            v-col.d-flex.child-flex(v-for='{imagen}, i in Clientes.empresas' :key='i' cols='2')
                v-row(align='center' justify='center')
                    img(:src='imagen' width='160px' contain).pl-5.pr-5
                        
    .d-md-none
        v-container(fluid).pa-0
            .bloque-ubicacion-movil.azul
            .fila-texto-movil
                p(v-html='Clientes.textomovil' style='line-height : 4px').font-weight-regular.white--text.d-sm-none
                img(:src='Inicio.cuadro_recortado')
            
        
        v-sheet.mx-auto(elevation='0' max-width='100%').py-4
            v-slide-group.pa-4(show-arrows='')
                v-slide-item(cols='11' v-for='{imagen}, i in Clientes.empresas'  :key='i' v-scrollanimation card='true')
                    v-row(align='center' justify='center')
                        img(:src='imagen' width='150px' contain).pl-5.pr-5
       
    
            
</template>
<script>
    export default {
        data: () => ({
        model: null,
        }),
    }
    </script>
<style lang="sass" scoped>
        
    .bloque-ubicacion
        display: flex
        flex-direction: column
        padding-top: 30px
        padding-bottom: 80px
        width: 100%
        height: 300px
    
    .fila-texto
        position: absolute
        display: flex 
        top: 5%
        left: 10%
        img
            position: absolute
            display: flex
            width: 80px
            height: 70px
            top: 90%
            left: -2em
    .fila-texto-movil
        position: absolute
        display: flex
        top: 3em
        left: 2em
        font-size: 14px
        img
            position: absolute
            display: flex
            width: 30px
            height: 30px
            top: 4rem
            left: -1em
    .bloque-ubicacion-movil
        display: flex
        flex-direction: column
        padding-top: 30px
        padding-bottom: 80px
        width: 100%
        height: 120px
       
    .fila-texto-movil
        position: absolute
        display: flex
        top: 3em
        left: 2em
        font-size: 14px
        img
            position: absolute
            display: flex
            width: 30px
            height: 30px
            top: 1.4rem
            left: -1em
    </style>